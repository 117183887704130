<template>
  <div class="row">
    <div class="col-12">
      <q-form ref="editForm">
        <c-card class="cardClassDetailForm" title="기본정보">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- <c-btn v-if="editable && !disabled" :showLoading="false" label="완료" @btnClicked="resultComplete" />
              <c-btn
                v-if="editable && !disabled"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="result"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" 
              /> -->
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-select
                label="점검유형"
                :disabled="true"
                codeGroupCd="MDM_CHECK_KIND_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="equipmentCheckKindCd"
                v-model="result.equipmentCheckKindCd"
              ></c-select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-select
                codeGroupCd="MIM_CHECK_RESULT_CD"
                :editable="editable"
                :disabled="disabled"
                required
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkResultCd"
                label="점검결과"
                v-model="result.checkResultCd"
              ></c-select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :required="true"
                :disabled="disabled"
                label="실제점검자"
                name="checkUserId"
                type="user" 
                v-model="result.checkUserId">
              </c-field>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="실제점검일"
                name="checkDate"
                v-model="result.checkDate">
              </c-datepicker>
            </div>
            <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :disabled="disabled"
                v-model="result.plantCd"
              />
            </div> -->
            <!-- <div class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
              <c-select
                :comboItems="chkRsltYnItems"
                :editable="editable"
                required
                :disabled="disabled"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkCompleteFlag"
                label="점검완료여부"
                v-model="result.checkCompleteFlag"
              ></c-select>
            </div> -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <c-moc 
                :disabled="disabled"
                :editable="editable"
                label="MOC번호"
                name="sopMocId"
                v-model="result.sopMocId">
              </c-moc>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="점검결과 요약"
                name="checkResultSummary"
                v-model="result.checkResultSummary">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :imprTabInfo.sync="imprTabInfo"
            :research.sync="research"
            :result.sync="result"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn :flat="true" v-if="editable && !disabled" :showLoading="false" label="완료" @btnClicked="resultComplete" />
          <c-btn
            :flat="true"
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="result"
            mappingType="PUT"
            label="저장"
            icon="save"
            @beforeAction="saveInspection"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        selectData: [],
        equipmentCd: '',
        minEquipmentCheckId: '',
        checkDate: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantNm: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        mocNo: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'N',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',
        equipmentResultList: [],
        equipmentImproveModels: []
      },
      research: {
        impr: '',
        item: '',
      },
      tab: 'resultItemTab',
      tabItems: [
        { name: 'resultItemTab', icon: 'list', label: '점검항목', component: () => import(`${'./equipmentResultItemTab.vue'}`) },
        { name: 'impr', icon: 'account_tree', label: '개선관리', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`) },
      ],
      grid: {
        columns: [],
        data: [],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      saveUrl: 'transactionConfig.sop.min.equipment.result.insert.url',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      updateMode: false,
    };
  },
  computed: {
    disabled() {
      return this.result.checkStatusCd === 'MCSC000015'
    },
    imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['checkItemName'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
          {
            key: uid(),
            label: '검사방법', 
            name: 'checkWayName'
          },
          {
            key: uid(),
            label: '판정기준', 
            name: 'decisionBasis'
          },
          {
            key: uid(),
            label: '점검시 안전사항', 
            name: 'checkCaution'
          },
        ],
        // Mobile용 End
        key: this.popupParam.minEquipmentCheckId, // 
        ibmTaskTypeCd: 'ITT0000055',
        ibmTaskUnderTypeCd: 'ITTU000080',
        requestContents: this.result.equipmentName + '/' + this.result.checkDate,
        disabled: this.result.checkStatusCd === 'MCSC000015',
        isOld: Boolean(this.popupParam.minEquipmentCheckId),
        gridItem: {
          listUrl: selectConfig.sop.min.equipment.result.list.url,
          param: {
            minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
            imprFlag: true,
          },
          title: '점검결과별 개선사항 목록', // 점검 결과 별 개선사항 목록
          merge: [
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              align: 'left',
              sortable: true,
            },
            {
              name: 'judgmentResultTypeName',
              field: 'judgmentResultTypeName',
              label: '판정',
              align: 'center',
              style: 'width:110px',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.update.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.complete.url;
      // code setting
    
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.minEquipmentCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.result = _result.data;
          this.updateMode = true;
        },);
      }
    },
    rowClick(row, col) {
      if (col.name === 'mocNo') {
        this.linkClick(row);
      } else {
        this.result = row;
      }
    },
    linkClick(row) {
      this.popupOptions.title = "설비관련 MOC 검색"; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.row = row;
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (!data) return;
      
      this.row.mocNo = data.chngNum
    },
    searchMocMaster() {
      this.popupOptions.title = '설비관련 MOC 검색'; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    removeMocMaster() {
      this.result.mocNo = '';
    },
    saveInspection() {
      if (this.popupParam.minEquipmentCheckId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      }
      if (this.popupParam.noPlan === 'Y') {
        this.result.noPlanResultEnrollFlag = 'Y';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.minEquipmentCheckId = result.data
      this.getDetail();
    },
    // 단일 점검완료
    resultComplete() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '점검완료 하시겠습니까?',
            // TODO : 점검완료 하시겠습니까?
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.result.checkCompleteFlag = 'Y';
              this.$http.url = this.completeUrl;
              this.$http.type = 'PUT';
              this.$http.param = this.result;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDetail();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
          },
        });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
        
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    tabClick() {
      this.$set(this.research, 'impr', uid())
    }
  }
};
</script>
